export const socialMedia=[
    {
        title:"facebook",
       link:"https://www.facebook.com/thecobraprotocol",
    },
    {
        title:"instagram",
        link:"https://www.instagram.com/cobraprotocol/",
    },
    {
        title:"telegram",
        link:"https://t.me/cobraprotocol",
    },
    {
        title:"thread",
        link:"https://www.threads.net/@cobraprotocol",

    },
    {
        title:"twitter",
        link:"https://x.com/cobraprotocol",
       
    }
]