import { addSocialLinkApi, getSocialLinkApi } from "../constent/api";
import * as opsService from "./Ops";
export const getSocialFun = async (token) => {
    let result = await opsService.getData(getSocialLinkApi,token);
    return result;
  };

export const addSocialLinkFun = async (data, token) => {
    let result = await opsService.postdata(addSocialLinkApi, data, token);
    return result;
  };  
  