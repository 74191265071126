import React from "react";
import { Modal, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

export const AgreementModal = ({ showAgreeModal, agreeHandle }) => {
  return (
    <>
      <Modal show={showAgreeModal} className="eligible-view">
        <Modal.Body>
          <div className="wallet-connet">
            <div className="wallet-connet-r">
              
              <div className="wallet-connet-r-box">
                <h4>Cobra Protocol Terms and Conditions</h4>
                <div className="cobra-tream">
                  <p>TOKEN Cobra Protocol TERMS</p>
                  <p>Last Revised on August 9,2024</p>
                  <p>
                  Welcome to the Token Airdrop Terms (these "Airdrop Terms") for the $COBRA token airdrop (the “Airdrop”) by $COBRA Foundation (“Organization”, “we” or “us”). These Airdrop Terms govern your ability to participate in the Airdrop. Please read these Airdrop Terms carefully, as they include important information about your legal rights. By participating in the Airdrop or claiming Airdrop tokens, you are agreeing to these Airdrop Terms. If you do not understand or agree to these Airdrop Terms, please do not participate in the Airdrop.
                  </p>
                  <p>
                  For purposes of these Airdrop Terms, “you” and “your” means you as the participant in the Airdrop. If you participate in the Airdrop or otherwise claim Airdrop tokens on behalf of a company or other entity then “you” includes you and that entity, and you represent and warrant that (a) you are an authorized representative of the entity with the authority to bind the entity to these Airdrop Terms, and (b) you agree to these Airdrop Terms on the entity’s behalf.
                  </p>
                </div>
                <p className="bu-using">
                  By using this website, you acknowledge and agree to the Token
                  Airdrop Terms
                </p>
                <a onClick={()=>agreeHandle()} className="btn btn-secondary">
                  Agree to Terms
                </a>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
