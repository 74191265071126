import React, { useState, useEffect } from "react";
import { Eligilibility } from "./partials/Eligilibility";
import { useNavigate } from "react-router-dom";
export const Home = () => {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  useEffect(() => {
    let token = localStorage.getItem("jwtToken");

    if (!token) {
      navigate("/");
    }
  }, []);
  return (
    <>
      <div className>
        <div id="particles-js"></div>
        <ul className="home-social">
          <li>
            <a href="https://www.facebook.com/thecobraprotocol" target="_blank">
              <i className="fab fa-facebook-f" aria-hidden="true" />
            </a>
          </li>
          <li>
            <a href="https://x.com/cobraprotocol" target="_blank">
              <i className="fab fa-twitter" aria-hidden="true" />
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/cobraprotocol/" target="_blank">
              <i className="fab fa-instagram" aria-hidden="true" />
            </a>
          </li>
          <li>
            <a href="https://t.me/cobraprotocol" target="_blank">
              <i className="fa-brands fa-telegram" aria-hidden="true" />
            </a>
          </li>
          <li>
            <a href="https://www.threads.net/@cobraprotocol" target="_blank">
              <i className="fa-brands fa-square-threads" aria-hidden="true"/>
            </a>
          </li>
        </ul>
        <main className="s-home s-home--static">
          <div className="home-content">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-xs-12">
                  <div className="navi">
                    <a href="javascript:void(0);">
                      <img src="/assets/img/logo.png" className="logo-fix" />
                    </a>
                    <button
                      //   variant="primary"
                      onClick={handleShow}
                      className="btn btn-primary"
                    >
                      Eligibility Criteria
                    </button>
                    {/* <a href="javascript:void(0);" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModalThree">Eligibility Criteria</a> */}
                    <Eligilibility show={showModal} handleClose={handleClose} />
                  </div>
                  <div className="home-content-main">
                    <div className="multi-img">
                      <img
                        src="/assets/img/hero-10.png"
                        className="img-fluid working_img"
                      />
                    </div>
                    <div className="home-content-text">
                      <span>Welcome to Cobra Protocol</span>
                      <h1>
                        We are currently working on a new super Cobra Protocol.
                      </h1>
                      <p>
                        Unleashing the future of crypto mining promises enhanced
                        efficiency, sustainability, and profitability,
                        revolutionizing the digital currency landscape and
                        driving innovation
                      </p>
                      <a href="/register" className="btn btn-primary">
                        Register
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};
