import { baseUrl } from "./Baseurl";

export const checkReferralCodeApi = baseUrl+"/check-referral-code"
export const registerUserApi = baseUrl+"/register-user"
export const checkAgreeMentApi = baseUrl+"/check-agreement"
export const updateAgreeMentApi = baseUrl+"/update-agreement"
export const loginUserApi = baseUrl+"/login-user"
export const insertUpdateProfileApi = baseUrl+"/insert-update-profile"
export const getUserDataApi = baseUrl+"/get-user-data"
// export const updateCoinApi = baseUrl+"/update-coin"
export const updateCoinApi = baseUrl+"/social_update"

export const sumAllCoinApi = baseUrl+"/sum-all-coin"

export const getSocialLinkApi=baseUrl+"/get-social-link"
export const addSocialLinkApi=baseUrl+"/add-social-link"


