import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Register } from "./components/auth/Register";
import { Home } from "./components/Home";
import { Desboard } from "./components/Desboard";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
function App() {
  return (
    <div className="App">
         <Router>
        <Routes>
        <Route path="/" element={<Home />} />
          <Route path="/register" element={<Register />} />
          
          <Route path="/dashboard" element={<Desboard />} />
          <Route path="/register/referral/:walletAddress" element={<Register />} />
        </Routes>
      </Router>
      <ToastContainer
      className= 'custom-toast'
      position= "top-center"
      autoClose={2000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
    />
    </div>
  );
}

export default App;
